// var dateFormat = require("dateformat");
import { toast } from "react-toastify";

function getStorage() {
  return localStorage;
}

export function readToken() {
  return getStorage().getItem("token");
}

export const showError = (message) => {
  toast.error(message);
  toast.clearWaitingQueue();
};

export const showSuccess = (message) => {
  toast.error(message);
  toast.clearWaitingQueue();
};

export function read(key) {
  let data = JSON.parse(getStorage().getItem("data"));
  if (data) {
    return data[key];
  } else {
    return undefined;
  }
}

export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function write(data) {
  getStorage().setItem("data", JSON.stringify(data));
}

export function remove(key) {
  getStorage().removeItem(key);
}

export function clearAll() {
  getStorage().clear();
}

export function Sort(data, key, order) {
  if (order == "ASC") {
    return data.sort((a, b) =>
      (a[key] && typeof a[key] == "String" && a[key].toLowerCase()) >
      (b[key] && typeof a[key] == "String" && b[key].toLowerCase())
        ? 1
        : -1
    );
  } else {
    return data.sort((a, b) =>
      (a[key] && typeof a[key] == "String" && a[key].toLowerCase()) <
      (b[key] && typeof a[key] == "String" && b[key].toLowerCase())
        ? 1
        : -1
    );
  }
}

export function Search(data, txt) {
  if (data.length > 0) {
    let firstitem = data[0];
    let allkeys = Object.keys(firstitem);
    const filtered = data.filter((item) => {
      for (const k of allkeys) {
        if (item[k]) {
          if (item[k].toString().toLowerCase().includes(txt.toLowerCase())) {
            return true;
          }
        }
      }
    });
    return filtered;
  } else {
    return data;
  }
}

export function formatDate(date) {
  if (!date || date == "") {
    return "";
  }

  try {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    // if (month.length < 2) month = "0" + month;
    // if (day.length < 2) day = "0" + day;

    return year + "-" + month + "-" + day;
  } catch (error) {
    console.log(error);
    return "";
  }
}

export function getDate(date) {
  try {
    let d = new Date(date);
    return d.toLocaleDateString("en-US");
  } catch (error) {
    return "";
  }
}

export function getDateFull(date) {
  try {
    const event = new Date(date);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return event.toLocaleDateString(undefined, options);
  } catch (error) {
    return "";
  }
}



//
export function generateRandomString(length = 15) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

export function getAmPm(date) {
  try {
    let d = new Date(date),
      hour = d.getHours();

    if (hour >= 12) {
      return "PM";
    } else {
      return "AM";
    }
  } catch (error) {
    return "";
  }
}

export function getHourMinute(date) {
  try {
    let d = new Date(date),
      hour = d.getHours(),
      minutes = d.getMinutes();

    if (hour >= 12) {
      hour -= 12;
    }
    return (
      String(hour).padStart(2, "0") + ":" + String(minutes).padStart(2, "0")
    );
  } catch (error) {
    return "";
  }
}
/**
 * get date in dd/mm/yyyy
 */

export function viewDateDDMMYYY(date) {
  try {
    let d = new Date(date),
      year = d.getFullYear(),
      month = d.getMonth(),
      day = d.getDate();

    return String(day).padStart(2, "0") + "/" + String((month+1)).padStart(2, "0") + "/" + year;
  } catch (error) {
    return "";
  }
}