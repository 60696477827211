import chart from "../assets/images/chart.png";
import logoColor from "../assets/images/logo-color.svg";
import logoMob from "../assets/images/logo-mob.svg";
import logoWhite from "../assets/images/logo-white.svg";
import useIcon from "../assets/images/useIcon.png";
import searchIcon from "../assets/images/Icon-search.png";

const images = {
  chart,
  logoColor,
  logoMob,
  logoWhite,
  useIcon,
  searchIcon,
};

export default images;
