import { useSelector } from "react-redux";

export function useUser() {
  return useSelector((state) => state.user);
}

export function useStaticPages() {
  return useSelector((state) => state.staticPage);
}

export function useCreator() {
  return useSelector((state) => state.creator);
}

export function useLastLogin() {
  const user = useSelector((state) => state.user);
  return user.lastLogin;
}

export function UsersContentSharedwith() {
  const user = useSelector((state) => state.user);
  return user.UsersContentSharedwith;
}

export function CreatorWalletWithDrawal() {
  const user = useSelector((state) => state.user);
  return {
    walletWithDrawal: user.walletWithDrawal,
    totalElements: user.total_walletWithDrawal,
  };
}

export function CreatorPaymentHistory() {
  const user = useSelector((state) => state.user);
  return {
    paymentHistory: user.paymentHistory,
    totalElements: user.total_paymentHistory,
  };
}

export function CreatorSubscriptions() {
  const user = useSelector((state) => state.user);
  return {
    subscriptions: user.subscriptions,
    totalElements: user.total_subscriptions,
  };
}

export function useReports() {
  const user = useSelector((state) => state.user);
  return {
    reports: user.reports,
    totalElements: user.total_reports,
  };
}

export function useSubscribers() {
  const user = useSelector((state) => state.user);
  return {
    subscribers: user.subscribers,
    totalElements: user.total_subscribers,
    allSubscribers: user.all_subscribers
  };
}

export function useDashboard() {
  const dashboard = useSelector((state) => state.dashboard);
  return dashboard;
}
