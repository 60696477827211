// Login/Signup
export const LoginUser = "LoginUser";
export const ResetPassword = "ResetPassword";

export const LoadUserToApprove = "LoadUserToApprove";
export const LoadUserForManagement = "LoadUserForManagement";
export const DeleteUser = "DeleteUser"
export const LoadlCreatorContent = "LoadlCreatorContent";
export const LoadSubscriberForManagement = "LoadSubscriberForManagement";

export const UserApproveApi = "UserApproveApi";

export const LoadLastLogin = "LoadLastLogin";
export const ListUsersContentSharedwith = "ListUsersContentSharedwith";

export const ListContentSharedwith = "ListContentSharedwith";

export const SetUserAsFree = "SetUserAsFree";
export const LoadMoney = "LoadMoney";

export const ApproveUser = "ApproveUser";

export const ListCreatorContentX = "ListCreatorContentX";
export const ListCreatorContentR = "ListCreatorContentR";
export const ListCreatorPrivatePics = "ListCreatorPrivatePics";
export const ListCreatorProfilePics = "ListCreatorProfilePics";

// only store
export const SetCurrentCreator = "SetCurrentCreator";
export const LoadSetFreeProfile = "LoadSetFreeProfile";

// creator sort
export const SortCreator = "SortCreator";
export const LoadSortCreator = "LoadSortCreator";

export const LoadWithDrawalRequest = "LoadWithDrawalRequest";
export const ApproveWithDrawalRequest = "ApproveWithDrawalRequest";

export const LoadWithPaymentHistory = "LoadWithPaymentHistory";
export const LoadWithSubscriptions = "LoadWithSubscriptions";

export const LoadReports = "LoadReports";
export const ResolveReports = "ResolveReports";
export const getStaticPage = "getStaticPage";
export const saveStaticPage = "saveStaticPage";

// dashboard
export const LoadDashboardCode = "LoadDashboardCode";
export const LoadCreatorEarnings = "LoadCreatorEarnings";
export const LoadConsumerExpense = "LoadConsumerExpense";
