import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { Flip, ToastContainer } from "react-toastify";
import { userData, set } from "./../redux/userReducer";

import LoginScreen from "../screens/auth/LoginScreen";
import Home from "../screens/admin/Home";
import ApproveUsers from "../screens/admin/ApproveUsers";
import UserManagement from "../screens/admin/UserManagement";
import ViewConsumer from "../screens/admin/ViewConsumer";
import ViewCreator from "../screens/admin/ViewCreator";
import ViewCreatorContent from "../screens/admin/ViewCreatorContent";
import FilesSharedWithConsumer from "../screens/admin/FileSharedWithConsumer";
import SortCreators from "../screens/admin/SortCreators";
import PageNotFound from "../screens/PageNotFound";
import WalletWithdrawal from "../screens/admin/WalletWithdrawal";
import PaymentHistory from "../screens/admin/PaymentHistory";
import Subscriptions from "../screens/admin/Subscriptions";
import ReportManagement from "../screens/admin/ReportManagement";
import StaticPage from "../screens/admin/StaticPage";
import StaticPage1 from "../screens/admin/StaticPage1";
import Subscriber from "../screens/admin/subscriber";

const AppRouter = () => {
  const dispatch = useDispatch();
  const current_User = useSelector(userData);

  useEffect(() => {
    dispatch(set());
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer
        autoClose={3000}
        hideProgressBar
        draggable={false}
        limit={1}
        role="alert"
        icon={false}
        theme="dark"
        transition={Flip}
      />
      <Routes>
        {current_User.isLoggedIn === false ? (
          <>
            <Route path="/" element={<LoginScreen />} />
            {/* <Navigate to="/" /> */}
          </>
        ) : (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/approve-users" element={<ApproveUsers />} />
            <Route path="/user-management" element={<UserManagement />} />
            <Route path="/view-creator" element={<ViewCreator />} />
            <Route path="/view-consumer" element={<ViewConsumer />} />
            <Route path="/sort-creator" element={<SortCreators />} />
            <Route
              path="/view-creator/shared-files"
              element={<FilesSharedWithConsumer />}
            />
            <Route
              path="/view-creator/content"
              element={<ViewCreatorContent />}
            />
            <Route path="/withdrawal" element={<WalletWithdrawal />} />
            <Route path="/payment" element={<PaymentHistory />} />
            <Route path="/subscription" element={<Subscriptions />} />
            <Route path="/reportmanagement" element={<ReportManagement />} />
            <Route path="/staticpage" element={<StaticPage />} />
            <Route path="/staticpage/about" element={<StaticPage1 />} />
            <Route path="/staticpage/help" element={<StaticPage1 />} />
            <Route path="/staticpage/privacy" element={<StaticPage1 />} />
            <Route path="/staticpage/terms" element={<StaticPage1 />} />
            <Route path="/staticpage/complaints" element={<StaticPage1 />} />
            <Route path="/Subscriber" element={<Subscriber/>} />
          </>
        )}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
