import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import Pagination from "./../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { fetchData, saveData } from "../../utils/api/apiHelper";
import useCustomNavigate from "../../utils/hooks/useCustomNavigate";
import { userData } from "../../redux/userReducer";
import { DeleteUser } from "../../utils/api/actionConstants";
import { deleteData } from "../../utils/api/apiHelper";
import {
  LoadUserForManagement,
  SetCurrentCreator,
} from "../../utils/api/actionConstants";
import { renderDate } from "../../utils/helper";
import Images from "../../themes/Images";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const UserManagement = () => {
  const [NavigateTo] = useCustomNavigate();
  const [swalProps, setSwalProps] = useState({});
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(true);
  // const { swal, ...rest } = props;

  let PageSize = 10;

  const dispatch = useDispatch();
  const user = useSelector(userData);

  useEffect(() => {
    dispatch(
      saveData({
        action: LoadUserForManagement,
        data: {
          name: value,
          // isApproved: 1,
          limit: 10,
          offset: (currentPage - 1) * PageSize,
        },
      })
    );
  }, [dispatch, currentPage, value]);

  const viewUser = (usr) => {
    if (!usr.role || !usr.role.name) return;
    dispatch({ type: SetCurrentCreator, payload: usr });
    NavigateTo("/view-" + usr.role.name.toLowerCase(), false, { usr });
  };

  const deleteUser = (usr) => {
    MySwal.fire({
      title: <p>Delete User</p>,
      text: "Do you want to delete this user?",
      showCancelButton: true, // Add this line to show the cancel button
      cancelButtonText: 'Cancel',
    }).then((r) => {
      if (r.isConfirmed) {
        dispatch(
          deleteData({
            action: DeleteUser,
            data: { id: usr.id },
          })
        ).then((r) => {
          dispatch(
            saveData({
              action: LoadUserForManagement,
              data: {
                name: value,
                // isApproved: 1,
                limit: 10,
                offset: (currentPage - 1) * PageSize,
              },
            })
          );
        });
      } else {
        return;
      }
    });
  };

  const confirmDelete = async () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Delete User",
        text: "Do you want to delete this user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete!",
        cancelButtonText: "Cancel",
      }).then((result) => {
        resolve(result.isConfirmed);
      });
    });
  };

  return (
    <>
      <Header />
      <main>
        <SideBard />
        <div className="main">
          <div className="container">
            <div className="row">
              <div className="content-wrapper w-100 mb-5">
                <div className="d-flex aic jsb flw">
                  <h1 className="pageHeading my-4">User Management</h1>
                  <div className="searchbar my-4">
                    <div className="input-group d-flex">
                      <input
                        type="text"
                        className="form-control searchText"
                        placeholder="Find Something Here..."
                        value={value}
                        onChange={(e) => {
                          setValue(e.target.value);
                        }}
                      />
                      <button type="button" className="searchbtn">
                        <img src={Images.searchIcon} alt="search" />
                        <span className="btnLoader"></span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body pb-0">
                          <div className="table-responsive p-0">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th
                                    className="tal pl-4"
                                    style={{ maxWidth: "300px" }}
                                  >
                                    Name
                                  </th>
                                  <th className="tac">Role</th>
                                  <th className="tac">Sign Date</th>
                                  <th className="tar pr-4">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {user.userManagement &&
                                  user.userManagement.length > 0 &&
                                  user.userManagement.map((v, k) => {
                                    return (
                                      <tr key={k}>
                                        <td className="pl-4">
                                          <div className="d-flex aic">
                                            <div className="icon icon-circle">
                                              <div className="icon-user">
                                                <img
                                                  src={
                                                    v.profilePic ||
                                                    Images.useIcon
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                            <h6 className="text-bold">
                                              <span className="username">
                                                {v.name}
                                              </span>
                                            </h6>
                                          </div>
                                        </td>
                                        <td className="tac">
                                          <span className="userrole">
                                            {v.role?.name}
                                          </span>
                                        </td>
                                        <td className="tac">
                                          <span className="signindate">
                                            {renderDate(v.createdAt)}
                                          </span>
                                        </td>
                                        <td className="tar pr-4 actbtn">
                                          <span
                                            className="iconbtn"
                                            onClick={() => viewUser(v)}
                                          >
                                            <i className="far fa-eye"></i>
                                          </span>
                                          <span
                                            className="iconbtn"
                                            onClick={() => deleteUser(v)}
                                          >
                                            <i className="far fa-trash-alt"></i>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <Pagination
                          className="pagination-bar"
                          currentPage={currentPage}
                          totalCount={user.userManagementTotalUser}
                          pageSize={PageSize}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <SweetAlert2 {...swalProps} /> */}
        {/* <SweetAlert /> */}
      </main>
      <Footer />
    </>
  );
};

export default UserManagement;
