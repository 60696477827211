import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import { useCreator } from "../../hooks/storeHooks";
import useCustomNavigate from "../../utils/hooks/useCustomNavigate";
import { fetchData } from "../../utils/api/apiHelper";
import {
  ListCreatorProfilePics,
  ListCreatorContentR,
  ListCreatorContentX,
  ListCreatorPrivatePics,
} from "../../utils/api/actionConstants";
import { CleanupContentFiles } from "../../redux/creatorContent";
import VideoPlayer from "../../components/VideoPlayer";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const ViewCreatorContent = (props) => {
  const [NavigateTo] = useCustomNavigate();
  const [lastMessageAtX, setLastMessageAtX] = useState("");
  const [lastMessageAtR, setLastMessageAtR] = useState("");

  const [loadingR, setLoadingR] = useState(false);
  const [loadingX, setLoadingX] = useState(false);

  const localtion = useLocation();

  const dispatch = useDispatch();
  const Creator = useCreator();

  useEffect(() => {
    if (
      !localtion.state ||
      !localtion.state.creator ||
      !localtion.state.creator.id
    ) {
      NavigateTo("/");
    }
  }, []);

  const creator = localtion.state.creator;

  useEffect(() => {
    dispatch(
      fetchData({
        action: ListCreatorProfilePics,
        data: {
          id: creator.id,
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchData({
        action: ListCreatorPrivatePics,
        data: {
          id: creator.id,
        },
      })
    );
  }, [dispatch]);

  /**
   * this hook is for cleaning up the redux store when we move out.
   */
  useEffect(() => () => dispatch(CleanupContentFiles()), []);

  return (
    <>
      <Header />
      <main>
        <SideBard />
        <div className="main">
          <div className="container">
            <div className="row">
              <div className="content-wrapper w-100 mb-5 creator-content-top">
                <div className="d-flex aic jsb flw">
                  <h1 className="pageHeading my-4">Content Library For <span>: {creator?.name}</span></h1>
                </div>
                <div className="content creator-content-tab">
                  <div className="row">
               
                    <Tabs>
                    <TabList>
                      <Tab>Age Verification Images</Tab>
                      <Tab>Free Profile Images</Tab>
                      <Tab>Private Introductory Images</Tab>
                    </TabList>

                    <TabPanel>
                      <div className="row age-vrf-row">
                      <div className="col-6">
                        <div className="d-flex aic jsb flw ">
                          <h4 className="pageTitle-2"> Age Verification Image</h4>
                        </div>
                        <div className="col-12">
                          <div className="card-body lib-img age-vrf-imgbox">
                              {" "}
                              <img src={creator?.uploadProfilePicture} alt="" />{" "}
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex aic jsb flw ">
                          <h4 className="pageTitle-2"> ID Image</h4>
                        </div>
                        <div className="col-12">
                          <div className="card-body lib-img age-vrf-imgbox">
                              {" "}
                              <img src={creator?.photoIdentity} alt="" />{" "}
                          </div>
                        </div>
                      </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                    <div className="col-12">
                    <div className="d-flex aic jsb flw ">
                      <h4 className="pageTitle-2"> Free Profile Images</h4>
                    </div>
                    <div className="row free-prof-row">
                    {Creator.profilePics &&
                      Creator.profilePics.length > 0 &&
                      Creator.profilePics.map((v, k) => {
                        return (
                          <div className="col-3" key={k}>
                            <div className="card tac">
                              <div className="card-body lib-img age-vrf-imgbox">
                                {" "}
                                <img src={v.source} alt="" />{" "}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                    <div className="d-flex aic jsb flw ">
                      <h4 className="pageTitle-2">Private Images : <span>${Creator?.creator?.contentAmount}</span></h4>
                    </div>
                    <div className="row free-prof-row">
                    {Creator.private &&
                      Creator.private.length > 0 &&
                      Creator.private.map((v, k) => {
                        return (
                          <div className="col-3" key={k}>
                            <div className="card tac">
                              <div className="card-body lib-img age-vrf-imgbox">
                                {" "}
                                <img src={v.source} alt="" />{" "}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      </div>
                    </TabPanel>
                  </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default ViewCreatorContent;
