import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import userReducer from "./userReducer";
import folderReducer from "./folderReducer";
import creatorContent from "./creatorContent";
import staticpages from "./staticpages";
import dashboardReducer from "./dashboardReducer";

const appReducer = combineReducers({
  user: userReducer,
  folder: folderReducer,
  creator: creatorContent,
  staticPage: staticpages,
  dashboard: dashboardReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = (state, action) => {
  if (action.type === "SIGNOUT_REQUEST") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
// import { createStore } from 'redux'
// import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
// // import { configureStore } from "@reduxjs/toolkit";
// import userReducer from "./userReducer";
// import folderReducer from "./folderReducer";
// import creatorContent from "./creatorContent";
// import staticpages from "./staticpages";
// import dashboardReducer from "./dashboardReducer";

// const persistConfig = {
//   key: 'root',
//   storage,
// }
// const persistedReducer = persistReducer(persistConfig, {
//   reducer: {
//     user: userReducer,
//     folder: folderReducer,
//     creator: creatorContent,
//     staticPage: staticpages,
//     dashboard: dashboardReducer
//   },
// })

// export default () => {
//   let store = createStore(persistedReducer)
//   let persistor = persistStore(store)
//   return { store, persistor }
// }

// // export const store = configureStore({
// //   reducer: {
// //     user: userReducer,
// //     folder: folderReducer,
// //     creator: creatorContent,
// //     staticPage: staticpages,
// //     dashboard: dashboardReducer
// //   },
// // });
