import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import LastLogin from "../../components/LastLogin";
import Pagination from "./../../components/Pagination";
import { useDispatch } from "react-redux";
import { useCreator, UsersContentSharedwith } from "../../hooks/storeHooks";
import { resetContentSharedWith } from "../../redux/userReducer";
import useCustomNavigate from "../../utils/hooks/useCustomNavigate";
import { fetchData, saveData } from "../../utils/api/apiHelper";
import {
  LoadLastLogin,
  SetUserAsFree,
  ListUsersContentSharedwith,
  LoadSetFreeProfile,
  UserApproveApi,
  LoadCreatorEarnings,
} from "../../utils/api/actionConstants";
import { renderDate } from "../../utils/helper";
import { AppRoute } from "../../router/routerConstants";

const ViewCreator = (props) => {
  const [NavigateTo] = useCustomNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 10;

  const dispatch = useDispatch();
  const Creator = useCreator();
  const consumerList = UsersContentSharedwith();

  useEffect(() => {
    // console.log(Creator);
    if (!Creator || !Creator.creator || !Creator.creator.id) {
      NavigateTo("/");
    }
  }, []);

  useEffect(() => {
    // earned.
    dispatch(
      fetchData({
        action: LoadCreatorEarnings,
        data: {
          userId: Creator.creator?.id,
          action: "lifeTimeEarning",
        },
      })
    );
    dispatch(
      fetchData({
        action: LoadLastLogin,
        data: {
          id: Creator.creator?.id,
        },
      })
    );
    dispatch(
      fetchData({
        action: ListUsersContentSharedwith,
        data: {
          id: Creator.creator?.id,
        },
      })
    );
    dispatch(
      fetchData({
        action: LoadSetFreeProfile,
        data: {
          id: Creator.creator?.id,
        },
      })
    );
  }, []);

  const setAsFree = (user) => {
    dispatch(
      saveData({
        action: SetUserAsFree,
        data: {
          id: user?.id,
        },
      })
    );
  };

  const onApproveRejectSuspend = (status) => {
    dispatch(
      saveData({
        action: UserApproveApi,
        data: {
          id: Creator.creator?.id,
          status,
        },
      })
    );
  };

  /**
   * this hook is for cleaning up the redux store when we move out.
   */
  useEffect(() => () => dispatch(resetContentSharedWith()), []);
  return (
    <>
      <Header />
      <main>
        <SideBard />
        <div className="main">
          <div className="container">
            <div className="row">
              <div className="content-wrapper w-100 mb-5">
                <div className="d-flex aic jsb flw uao-hdr">
                  <h1 className="pageHeading my-4">User Account Overview</h1>
                  <div className="button_group d-flex jsb aic flw">
                    <button
                      type="button"
                      className="btn btn-primary my-4 me-4 viewupldbtn"
                      onClick={() => {
                        NavigateTo(AppRoute.CreatorContent, false, {
                          creator: Creator.creator,
                        });
                      }}
                    >
                      <i className="far fa-eye fa-circle"></i> View
                      <span className="btnLoader"></span>
                    </button>
                        {Creator.creator.isApproved === 0 && (
                          <button
                            type="button"
                            className={"btn btn-green me-2 mb-2"}
                            onClick={() => onApproveRejectSuspend("Approved")}
                          >
                            <i className="far fa-check-circle"></i> Approve
                            <span className="btnLoader"></span>
                          </button>
                        )}
                        {Creator.creator.isApproved == 1 && (
                          <button
                            type="button"
                            className="btn btn-primary me-2 mb-2"
                            onClick={(e) => {
                              setAsFree(Creator.creator);
                            }}
                          >
                            {Creator.setFreeProfile ? (
                              <>
                                <i className="fa"></i> Un-Set Free Profile
                              </>
                            ) : (
                              <>
                                <i className="fa fa-user-check"></i> Set Free
                                Profile
                              </>
                            )}
                            <span className="btnLoader"></span>
                          </button>
                        )}
                        {Creator.creator.isApproved == 0 && (
                          <button
                            type="button"
                            className={
                              "btn btn-red me-2 mb-2" +
                              (Creator.creator.isApproved == 2
                                ? "disabled"
                                : "")
                            }
                            onClick={() => onApproveRejectSuspend("Rejected")}
                          >
                            <i className="far fa-times-circle"></i> Reject
                            <span className="btnLoader"></span>
                          </button>
                        )}
                        {Creator.creator.isApproved == 1 && (
                          <button
                            type="button"
                            className={
                              "btn btn-primary mb-2 " +
                              (Creator.creator.isApproved == 3
                                ? "disabled"
                                : "")
                            }
                            onClick={() => onApproveRejectSuspend("Suspend")}
                          >
                            <i className="fa fa-user-alt-slash"></i> Suspend
                            <span className="btnLoader"></span>
                          </button>
                        )}
                        {Creator.creator.isApproved === 3 && (
                          <button
                            type="button"
                            className="btn btn-primary mb-2"
                            onClick={() => onApproveRejectSuspend("UnSuspend")}
                          >
                            <i className="fa fa-user-alt-slash"></i> Re Activate
                            <span className="btnLoader"></span>
                          </button>
                        )}
                      </div>
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-6">
                      <div className="card mb-4">
                        <div className="card-body">
                          <div className="d-flex userDetails">
                            <div className="icon icon-circle icon-lg">
                              <div className="icon-user">
                                <img src={Creator.creator.profilePic} alt="" />
                              </div>
                            </div>
                            <div className="userinfo">
                              <div className="mb-3">
                                <span className="text-bold lbl">Name <strong>:</strong></span> 
                                <span className="username val">
                                  {Creator.creator?.name}
                                </span>
                              </div>
                              <div className="mb-3">
                                <span className="text-bold lbl">Email <strong>:</strong></span>
                                <span className="username val">
                                  <span className="mlink">
                                    {Creator.creator?.email}
                                  </span>
                                </span>
                              </div>
                              <div className="mb-3">
                                <span className="text-bold lbl">Body Type <strong>:</strong></span>
                            
                                <span className="username val">
                                  {Creator.creator?.bodyType}
                                </span>
                              </div>
                              <div className="mb-3">
                                <span className="text-bold lbl">Age <strong>:</strong></span>
                                <span className="username val">
                                  {Creator.creator?.age}
                                </span>
                              </div>
                              <div className="mb-3">
                                <span className="text-bold lbl">Role <strong>:</strong></span>
                                <span className="username val">
                                  {Creator.creator?.role?.name}
                                </span>
                              </div>
                              <div className="mb-3">
                                <span className="text-bold lbl">
                                  Sign Up Date <strong>:</strong>
                                </span>
                              
                                <span className="username val">
                                  {renderDate(Creator.creator?.createdAt)}
                                </span>
                              </div>
                              <div className="mb-3">
                                <span className="text-bold lbl">Ethnicity <strong>:</strong></span>
                                
                                <span className="username val">
                                  {Creator.creator?.ethnicity}
                                </span>
                              </div>
                              <div className="">
                                <span className="text-bold lbl">Zipcode <strong>:</strong></span> 
                                <span className="username val">
                                  {Creator.creator?.zipcode}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mb-4">
                        <LastLogin />
                      </div>
                      <div className="card mb-4">
                        <div className="card-body">
                          <div className="d-flex moneydetails">
                            <div className="Money">
                              <div className="totalMoneyearn">
                                ${Creator.moneyEarned? Creator.moneyEarned: 0}
                              </div>
                            </div>
                            <div className="ipadrs">
                              <div className="lblmoney">Money Earned</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="pageTitle">Content</h3>
                        </div>
                        <div className="card-body pb-0">
                          <div className="table-responsive p-0">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="tal pl-4">Shared With</th>
                                  <th className="tac">Date</th>
                                  <th className="tar pr-4">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {consumerList &&
                                  consumerList.length > 0 &&
                                  consumerList.map((v, k) => {
                                    return (
                                      <tr key={k}>
                                        <td className="pl-4">
                                          <div className="d-flex aic">
                                            <div className="icon icon-circle">
                                              <div className="icon-user">
                                                <img
                                                  src="images/Rectangle-76.png"
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                            <h6 className="text-bold">
                                              <span className="username">
                                                {v?.user?.name}
                                              </span>
                                            </h6>
                                          </div>
                                        </td>
                                        <td className="tac">
                                          <span className="signindate">
                                            {renderDate(v?.user?.createdAt)}
                                          </span>
                                        </td>
                                        <td
                                          className="tar pr-4 actbtn"
                                          onClick={() => {
                                            NavigateTo(
                                              AppRoute.FilesSharedWith,
                                              false,
                                              {
                                                consumer: v.user,
                                                creator: Creator.creator,
                                              }
                                            );
                                          }}
                                        >
                                          <span className="iconbtn">
                                            <i className="far fa-eye"></i>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <Pagination
                          className="pagination-bar"
                          currentPage={currentPage}
                          totalCount={Creator.totalContent}
                          pageSize={PageSize}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default ViewCreator;
